import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { clearTData } from '../redux/slices/prothese/protheseSlice';
import { dispatch } from '../redux/store';
import LinearAlternativeLabel from '../components/Stepper/LinearAlternativeLabel';
export default function PatientForm() {
    useEffect(() => {
        dispatch(clearTData());
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Helmet, { children: _jsx("title", { children: " Cr\u00E9er Des Patients | Denty Share " }) }), _jsx(LinearAlternativeLabel, {})] }));
}
